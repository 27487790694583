import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ImgMediaCard from '../../components/ImgMediaCard/ImgMediaCard';
import SimpleCard from '../../components/Card/SimpleCard';
import { Link } from 'gatsby'

import './blog_styles.scss'
import Tags from '../../components/Tags/Tags';
import useFirestore from '../../modules/flamelink-module';
import _ from 'lodash';

export default function Blog() {
    const docs = useFirestore('fl_content', 'newSchema');
    return (
        <Layout>
            <SEO title="Blogs" description="" />
            <section className="blog_banner">
                <Container>
                    <div className="blog-heading">
                        <p>Blog</p>
                        <h6>Read Latest Company Updates and Unbiased Innovations</h6>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8}>
                            {
                                !_.isEmpty(docs) && docs.map((val, index) => {
                                    return (
                                        <Link key={index} to={`/blogs/${val.id}`} style={{ color: "inherit", textDecoration: "none" }}>
                                            <ImgMediaCard
                                                key={index}
                                                image={val.linkToThumnailImage}
                                                title={val.title}
                                                summary={val.summary}
                                                author={val.author}
                                                tags={val.tags}
                                            />
                                        </Link>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            {
                                !_.isEmpty(docs) && <SimpleCard article={docs} />
                            }
                            {
                                !_.isEmpty(docs) && <Tags tags={docs} />
                            }

                        </Grid>
                    </Grid>
                </Container>
            </section>
        </Layout>
    );
}
